import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from "./components/ui/button";
import { Input } from "./components/ui/input";
import { Card, CardContent, CardHeader, CardFooter, CardTitle } from "./components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "./components/ui/tabs";
import { Home, Search, DollarSign, Key, Home as HomeIcon, Users, Megaphone, HelpCircle, LogIn } from 'lucide-react';
import './RealEstatePlatform.css';
import App from './App';



export const Navigation: React.FC = () => (
    <nav className="nav-main">
        <div className="nav-container">
            <div className="nav-logo">HabitatHub</div>
            <div className="nav-links">
                <Button variant="ghost" asChild><Link to="/buy"><Search className="mr-1" />Comprar</Link></Button>
                <Button variant="ghost" asChild><Link to="/rent"><Key className="mr-1" />Alquilar</Link></Button>
                <Button variant="ghost" asChild><Link to="/sell"><DollarSign className="mr-1" />Vender</Link></Button>
                <Button variant="ghost" asChild><Link to="/homeloans"><HomeIcon className="mr-1" />Préstamos</Link></Button>
                <Button variant="ghost" asChild><Link to="/agent"><Users className="mr-1" />Encontrar Agente</Link></Button>
                <Button variant="ghost" asChild><Link to="/advertise"><Megaphone className="mr-1" />Publicidad</Link></Button>
                <Button variant="ghost" asChild><Link to="/support"><HelpCircle className="mr-1" />Ayuda</Link></Button>
                <Button variant="outline" asChild><Link to="/signin"><LogIn className="mr-1" />Iniciar Sesión</Link></Button>
            </div>
        </div>
    </nav>
);

export const HomePage: React.FC = () => (
    <div className="home-container">
        <h1 className="home-title">Bienvenido a HabitatHub</h1>
        <p className="home-subtitle">Encuentra tu propiedad perfecta</p>
        <Tabs defaultValue="buy" className="home-tabs">
            <TabsList>
                <TabsTrigger value="buy">Comprar</TabsTrigger>
                <TabsTrigger value="rent">Alquilar</TabsTrigger>
                <TabsTrigger value="sell">Vender</TabsTrigger>
            </TabsList>
            <TabsContent value="buy">
                <div className="search-container">
                    <Input placeholder="Ingresa la ubicación para comprar" className="search-input" />
                    <Button>Buscar</Button>
                </div>
            </TabsContent>
            <TabsContent value="rent">
                <div className="search-container">
                    <Input placeholder="Ingresa la ubicación para alquilar" className="search-input" />
                    <Button>Buscar</Button>
                </div>
            </TabsContent>
            <TabsContent value="sell">
                <div className="search-container">
                    <Input placeholder="Ingresa la dirección de tu propiedad" className="search-input" />
                    <Button>Obtener Estimación</Button>
                </div>
            </TabsContent>
        </Tabs>
        <div className="featured-properties">
            <h2 className="featured-title">Propiedades Destacadas</h2>
            <div className="property-grid">
                {[1, 2, 3].map((i) => (
                    <Card key={i}>
                        <img src={`/api/placeholder/400/300?text=Propiedad ${i}`} alt={`Propiedad ${i}`} className="property-image" />
                        <CardContent>
                            <h3 className="property-title">Hermosa Casa en Venta {i}</h3>
                            <p className="property-address">123 Calle Principal, Ciudad, Estado</p>
                            <p className="property-price">${(Math.random() * 500000 + 200000).toFixed(2)}</p>
                            <Button className="property-button">Ver Detalles</Button>
                        </CardContent>
                    </Card>
                ))}
            </div>
        </div>
    </div>
);


export const BuyPage: React.FC = () => (
    <div className="page-container">
        <h2 className="page-title">Compra Tu Casa de Ensueño</h2>
        <div className="property-grid">
            {[1, 2, 3, 4, 5, 6].map((i) => (
                <Card key={i}>
                    <img src={`/api/placeholder/400/300?text=Propiedad ${i}`} alt={`Propiedad ${i}`} className="property-image" />
                    <CardContent>
                        <h3 className="property-title">Hermosa Casa en Venta {i}</h3>
                        <p className="property-address">123 Calle Principal, Ciudad, Estado</p>
                        <p className="property-price">${(Math.random() * 500000 + 200000).toFixed(2)}</p>
                        <Button className="property-button">Ver Detalles</Button>
                    </CardContent>
                </Card>
            ))}
        </div>
    </div>
);

export const RentPage: React.FC = () => (
    <div className="page-container">
        <h2 className="page-title">Encuentra Tu Alquiler Perfecto</h2>
        <div className="property-grid">
            {[1, 2, 3, 4, 5, 6].map((i) => (
                <Card key={i}>
                    <img src={`/api/placeholder/400/300?text=Alquiler ${i}`} alt={`Alquiler ${i}`} className="property-image" />
                    <CardContent>
                        <h3 className="property-title">Acogedor Apartamento en Alquiler {i}</h3>
                        <p className="property-address">456 Calle Olmo, Ciudad, Estado</p>
                        <p className="property-price">${(Math.random() * 2000 + 1000).toFixed(2)}/mes</p>
                        <Button className="property-button">Ver Detalles</Button>
                    </CardContent>
                </Card>
            ))}
        </div>
    </div>
);

export const SellPage: React.FC = () => (
    <div className="page-container">
        <h2 className="page-title">Vende Tu Propiedad</h2>
        <Card>
            <CardContent className="sell-form">
                <h3 className="form-title">Obtén una Valoración Gratuita de Tu Casa</h3>
                <form className="form-content">
                    <Input placeholder="Dirección de la Propiedad" />
                    <Input placeholder="Ciudad" />
                    <Input placeholder="Estado" />
                    <Input placeholder="Código Postal" />
                    <Button className="form-button">Obtener Estimación</Button>
                </form>
            </CardContent>
        </Card>
    </div>
);

export const HomeLoansPage: React.FC = () => (
    <div className="page-container">
        <h2 className="page-title">Préstamos Hipotecarios</h2>
        <div className="loans-grid">
            <Card>
                <CardHeader>
                    <CardTitle>Calculadora de Hipotecas</CardTitle>
                </CardHeader>
                <CardContent>
                    <form className="form-content">
                        <Input type="number" placeholder="Precio de la Casa" />
                        <Input type="number" placeholder="Pago Inicial" />
                        <Input type="number" placeholder="Tasa de Interés" />
                        <Input type="number" placeholder="Plazo del Préstamo (años)" />
                        <Button className="form-button">Calcular</Button>
                    </form>
                </CardContent>
            </Card>
            <Card>
                <CardHeader>
                    <CardTitle>Opciones de Préstamos</CardTitle>
                </CardHeader>
                <CardContent>
                    <ul className="loan-options">
                        <li>Préstamos Convencionales</li>
                        <li>Préstamos FHA</li>
                        <li>Préstamos VA</li>
                        <li>Préstamos Jumbo</li>
                        <li>Refinanciamiento</li>
                    </ul>
                    <Button className="form-button">Comparar Tasas</Button>
                </CardContent>
            </Card>
        </div>
    </div>
);


export const FindAgentPage: React.FC = () => (
    <div className="page-container">
        <h2 className="page-title">Encuentra un Agente Inmobiliario</h2>
        <Input placeholder="Ingresa tu ubicación" className="location-input" />
        <div className="agent-grid">
            {[1, 2, 3, 4, 5, 6].map((i) => (
                <Card key={i}>
                    <img src={`/api/placeholder/150/150?text=Agente ${i}`} alt={`Agente ${i}`} className="agent-image" />
                    <CardContent className="agent-content">
                        <h3 className="agent-name">Juan Pérez {i}</h3>
                        <p className="agent-specialty">Experto Local</p>
                        <p className="agent-transactions">100+ transacciones</p>
                        <Button className="agent-button">Contactar Agente</Button>
                    </CardContent>
                </Card>
            ))}
        </div>
    </div>
);

export const AdvertisePage: React.FC = () => (
    <div className="page-container">
        <h2 className="page-title">Anuncia con Nosotros</h2>
        <Card>
            <CardContent className="advertise-content">
                <h3 className="advertise-subtitle">Llega a Millones de Buscadores de Casas</h3>
                <p className="advertise-description">Aumenta tu visibilidad y atrae más clientes anunciando en InmobiliariaVirtual.com</p>
                <ul className="advertise-features">
                    <li>Ubicación Premium de Listados</li>
                    <li>Perfiles Destacados de Agentes</li>
                    <li>Anuncios Banner Dirigidos</li>
                    <li>Campañas de Marketing por Correo Electrónico</li>
                </ul>
                <Button className="advertise-button">Comenzar</Button>
            </CardContent>
        </Card>
    </div>
);

export const HelpPage: React.FC = () => (
    <div className="page-container">
        <h1 className="page-title">Centro de Ayuda</h1>
        <div className="help-grid">
            <Card>
                <CardHeader>
                    <CardTitle>Preguntas Frecuentes</CardTitle>
                </CardHeader>
                <CardContent>
                    <ul className="faq-list">
                        <li><Button variant="link">¿Cómo busco propiedades?</Button></li>
                        <li><Button variant="link">¿Cuál es el proceso para comprar una casa?</Button></li>
                        <li><Button variant="link">¿Cómo puedo listar mi propiedad para la venta?</Button></li>
                        <li><Button variant="link">¿Cuáles son los pasos para solicitar una hipoteca?</Button></li>
                        <li><Button variant="link">¿Cómo contacto a un agente inmobiliario?</Button></li>
                    </ul>
                </CardContent>
            </Card>
            <Card>
                <CardHeader>
                    <CardTitle>Contactar Soporte</CardTitle>
                </CardHeader>
                <CardContent>
                    <form className="support-form">
                        <Input placeholder="Tu Nombre" />
                        <Input type="email" placeholder="Tu Correo Electrónico" />
                        <textarea className="support-textarea" rows={4} placeholder="Tu Pregunta"></textarea>
                        <Button type="submit" className="support-button">Enviar</Button>
                    </form>
                </CardContent>
            </Card>
        </div>
        <Card className="mt-8">
            <CardHeader>
                <CardTitle>Servicio al Cliente</CardTitle>
            </CardHeader>
            <CardContent>
                <p><strong>Teléfono:</strong> +52 (449) 590-0184</p>
                <p><strong>Email:</strong> soporte@habitathub.net</p>
                <p><strong>Horario de Atención:</strong> Lunes a Viernes, 9:00 AM - 6:00 PM CST</p>
            </CardContent>
        </Card>
    </div>
);

export const PrivacyPolicyPage: React.FC = () => (
    <div className="page-container">
        <h1 className="page-title">Política de Privacidad</h1>
        <Card>
            <CardContent>
                <h2 className="text-xl font-semibold mb-4">1. Información que Recopilamos</h2>
                <p className="mb-4">Recopilamos información personal como su nombre, dirección de correo electrónico, número de teléfono y preferencias de búsqueda de propiedades cuando utiliza nuestro sitio web.</p>

                <h2 className="text-xl font-semibold mb-4">2. Cómo Utilizamos su Información</h2>
                <p className="mb-4">Utilizamos su información para proporcionarle servicios personalizados, mejorar nuestro sitio web y comunicarnos con usted sobre propiedades que puedan interesarle.</p>

                <h2 className="text-xl font-semibold mb-4">3. Compartir Información</h2>
                <p className="mb-4">No vendemos su información personal. Podemos compartir su información con agentes inmobiliarios y proveedores de servicios que nos ayudan a operar nuestro sitio web.</p>

                <h2 className="text-xl font-semibold mb-4">4. Sus Derechos</h2>
                <p className="mb-4">Tiene derecho a acceder, corregir o eliminar su información personal. También puede optar por no recibir comunicaciones de marketing.</p>

                <h2 className="text-xl font-semibold mb-4">5. Seguridad</h2>
                <p className="mb-4">Tomamos medidas de seguridad para proteger su información personal contra acceso no autorizado y uso indebido.</p>

                <h2 className="text-xl font-semibold mb-4">6. Cambios en esta Política</h2>
                <p>Podemos actualizar esta política de privacidad periódicamente. Le notificaremos sobre cambios significativos publicando una notificación en nuestro sitio web.</p>
            </CardContent>
        </Card>
    </div>
);

export const SignInPage: React.FC = () => (
    <div className="signin-container">
        <Card>
            <CardHeader>
                <CardTitle className="signin-title">Iniciar Sesión</CardTitle>
            </CardHeader>
            <CardContent>
                <form className="signin-form">
                    <Input type="email" placeholder="Correo Electrónico" />
                    <Input type="password" placeholder="Contraseña" />
                    <Button type="submit" className="signin-button">Iniciar Sesión</Button>
                </form>
                <div className="forgot-password">
                    <Button variant="link">¿Olvidaste tu Contraseña?</Button>
                </div>
                <div className="signup-prompt">
                    <p>¿No tienes una cuenta? <Button variant="link">Registrarse</Button></p>
                </div>
            </CardContent>
        </Card>
    </div>
);


export const TermsOfServicePage: React.FC = () => {
    const [accepted, setAccepted] = useState(false);

    const handleAccept = () => {
        setAccepted(true);
    };

    const onDecline = () => {
        alert("Debe aceptar los términos de servicio para continuar.");
    };

    return (
        <div className="page-container">
            <h1 className="page-title">Términos de Uso</h1>
            <Card className="terms-of-service-card">
                <CardContent className="terms-content">
                    {/* Placeholder for actual terms of service content */}
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua...</p>
                    {/* Repeat the above paragraph multiple times to simulate a long document */}
                    {Array(20).fill(0).map((_, index) => (
                        <p key={index}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua...</p>
                    ))}
                </CardContent>
                <CardFooter className="terms-footer">
                    <div className="terms-actions">
                        <Button onClick={handleAccept} disabled={accepted}>Aceptar</Button>
                        <Button onClick={onDecline}  disabled={accepted}>Declinar</Button>
                    </div>
                </CardFooter>
            </Card>
        </div>
    );
};

export const Footer: React.FC = () => (
    <footer className="footer">
        <div className="footer-content">
            <Button variant="link" asChild><Link to="/tos">Términos de Uso</Link></Button>            
            &copy; 2024 HabitatHub.net. Todos los derechos reservados.
            <Button variant="link" asChild><Link to="/privacy">Política de Privacidad</Link></Button>
        </div>
    </footer>
);
