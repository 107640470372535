import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Navigation, HomePage, BuyPage, RentPage, SellPage, HomeLoansPage, FindAgentPage,AdvertisePage,HelpPage, SignInPage, PrivacyPolicyPage, TermsOfServicePage,Footer } from './RealEstatePlatform';
// Import other page components as needed

const App: React.FC = () => {
  return (
    <Router>
      <div className="app-container">
        <Navigation />
        <main className="main-content">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/buy" element={<BuyPage />} />
            <Route path="/rent" element={<RentPage />} />
            <Route path="/sell" element={<SellPage />} />
            <Route path="/homeloans" element={<HomeLoansPage />} />
            <Route path="/agent" element={<FindAgentPage />} />
            <Route path="/advertise" element={<AdvertisePage />} />
            <Route path="/support" element={<HelpPage />} />
            <Route path="/signin" element={<SignInPage />} />
            <Route path="/privacy" element={<PrivacyPolicyPage />} />
            <Route path="/tos" element={<TermsOfServicePage />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
};

export default App;